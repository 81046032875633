<template>
  <GallerOverlayStyled>
    <Modal :errors="errors">
      <template v-slot:header>
        <span>Gallery</span>
      </template>
      <template v-slot:main>
        <UploadsGalleryOrganism
          :allowedUploadTypes="['IMAGE']"
          :previewColor="previewColor"
          :previewBackground="previewBackground"
          :selectedUpload="selectedUpload"
          @select-upload="args.onSelectUpload"
        />
      </template>
      <template v-slot:footer>
        <ActionsStyled>
          <IconButtonAtom @click="$emit('close')">
            {{ $t('actions.cancel') }}
          </IconButtonAtom>
        </ActionsStyled>
      </template>
    </Modal>
  </GallerOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { Modal } from '@common/components'

import IconButtonAtom from '@/components/Atomic/Atoms/IconButtonAtom'
import UploadsGalleryOrganism from '@/components/Atomic/Organisms/UploadsGalleryOrganism'

const GallerOverlayStyled = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  padding-top: 50px;
`

const ActionsStyled = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
`

export default {
  props: {
    args: {
      type: Object,
    },
  },
  components: {
    GallerOverlayStyled,
    ActionsStyled,
    UploadsGalleryOrganism,
    IconButtonAtom,
    Modal,
  },
  data() {
    return {
      errors: [],
      isConfirming: false,
    }
  },
  computed: {
    previewColor() {
      return this.args?.previewColor
    },
    previewBackground() {
      return this.args?.previewBackground
    },
    selectedUpload() {
      return this.args?.selectedUpload
    },
  },
}
</script>
