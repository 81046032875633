var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UploadListMolecule', [_vm._l(_vm.uploads, function (upload) {
    var _upload$connectedProd, _vm$selectedUpload;
    return _c('ImagePreviewMolecule', {
      key: upload.id,
      staticClass: "upload",
      class: {
        'has-connected-product': (_upload$connectedProd = upload.connectedProduct) === null || _upload$connectedProd === void 0 ? void 0 : _upload$connectedProd.id,
        'is-selected': ((_vm$selectedUpload = _vm.selectedUpload) === null || _vm$selectedUpload === void 0 ? void 0 : _vm$selectedUpload.id) && _vm.selectedUpload.id === upload.id
      },
      attrs: {
        "previewColor": _vm.previewColor,
        "previewBackground": _vm.previewBackground,
        "upload": upload
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('select', upload);
        }
      }
    });
  }), _c('InfiniteLoading', {
    on: {
      "infinite": function infinite($event) {
        return _vm.$emit('fetch-more', $event);
      }
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }