var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('UploadListMolecule', {
    attrs: {
      "uploads": _vm.uploads,
      "previewColor": _vm.previewColor,
      "previewBackground": _vm.previewBackground,
      "selectedUpload": _vm.selectedUpload
    },
    on: {
      "fetch-more": _vm.fetchMore,
      "select": function select($event) {
        return _vm.$emit('select-upload', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }