<template>
  <UploadListMolecule
    :uploads="uploads"
    :previewColor="previewColor"
    :previewBackground="previewBackground"
    :selectedUpload="selectedUpload"
    @fetch-more="fetchMore"
    @select="$emit('select-upload', $event)"
  />
</template>

<script>
import UPLOADS_QUERY from '#/graphql/uploads/uploadsQuery.gql'

import UploadListMolecule from '../Molecules/UploadListMolecule.vue'

export default {
  props: {
    allowedUploadTypes: {
      type: Array,
      validator: arr => {
        return arr.every(e => ['IMAGE', 'OTHER'].includes(e))
      },
    },
    previewColor: {
      type: String,
      required: true,
    },
    previewBackground: {
      type: String,
      required: true,
    },
    selectedUpload: {
      type: Object,
    },
  },
  components: {
    UploadListMolecule,
  },
  data() {
    return {
      canLoadMore: true,
      uploads: [],
      cursor: undefined,
    }
  },
  methods: {
    async fetchMore($state) {
      try {
        const res = await this.$apollo.query({
          query: UPLOADS_QUERY,
          variables: {
            cursor: this.cursor,
            where: {
              type: {
                in: this.allowedUploadTypes,
              },
            },
            take: 9,
            skip: this.cursor !== undefined ? 1 : undefined,
            orderBy: {
              updatedAt: 'desc',
            },
          },
          fetchPolicy: 'network-only',
        })
        this.uploads = this.uploads.concat(res?.data?.uploads ?? [])
        if (res?.data?.uploads?.length < 9) {
          $state.complete()
        } else {
          const last = res?.data?.uploads[res?.data?.uploads?.length - 1]
          this.cursor = {
            updatedAt_id: {
              updatedAt: last.updatedAt,
              id: last.id,
            },
          }
          $state.loaded()
        }
      } catch (err) {
        $state.error()
      }
    },
  },
}
</script>
