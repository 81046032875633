<template>
  <UploadListMolecule>
    <ImagePreviewMolecule
      v-for="upload in uploads"
      class="upload"
      :class="{
        'has-connected-product': upload.connectedProduct?.id,
        'is-selected': selectedUpload?.id && selectedUpload.id === upload.id,
      }"
      :key="upload.id"
      :previewColor="previewColor"
      :previewBackground="previewBackground"
      :upload="upload"
      @click="$emit('select', upload)"
    />
    <InfiniteLoading @infinite="$emit('fetch-more', $event)" />
  </UploadListMolecule>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import InfiniteLoading from 'vue-infinite-loading'

import ImagePreviewMolecule from './ImagePreviewMolecule'

const UploadListMolecule = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 1rem;

  > .upload {
    cursor: pointer;
    user-select: none;
    &:not(.has-connected-product):hover,
    &.is-selected {
      box-shadow: 0 0 7px 2px ${({ theme }) => theme.colors.primary};
    }

    &.has-connected-product,
    &.is-selected {
      pointer-events: none;
      cursor: default;
      opacity: 0.5;
    }
  }
  .infinite-loading-container {
    grid-column: span 3;
  }
`

export default {
  props: {
    uploads: {
      type: Array,
      required: true,
    },
    previewColor: {
      type: String,
      required: true,
    },
    previewBackground: {
      type: String,
      required: true,
    },
    selectedUpload: {
      type: Object,
    },
  },
  components: {
    UploadListMolecule,
    InfiniteLoading,
    ImagePreviewMolecule,
  },
  methods: {},
}
</script>
