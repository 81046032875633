var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('GallerOverlayStyled', [_c('Modal', {
    attrs: {
      "errors": _vm.errors
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', [_vm._v("Gallery")])];
      },
      proxy: true
    }, {
      key: "main",
      fn: function fn() {
        return [_c('UploadsGalleryOrganism', {
          attrs: {
            "allowedUploadTypes": ['IMAGE'],
            "previewColor": _vm.previewColor,
            "previewBackground": _vm.previewBackground,
            "selectedUpload": _vm.selectedUpload
          },
          on: {
            "select-upload": _vm.args.onSelectUpload
          }
        })];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('ActionsStyled', [_c('IconButtonAtom', {
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('actions.cancel')) + " ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }